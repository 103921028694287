<template>
  <div>
    <div v-if="metadata !== null" class="space-y-4">
      <div class="text-xs text-black dark:text-white">
        my wallet: {{ addr }}
      </div>
      <div class="text-xs text-black dark:text-white">
        designer wallet: {{ addr_designer }}
      </div>

      <div class="bg-white rounded-xl p-4">
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <h2 class="text-4xl mb-4">{{ metadata.name }}</h2>
            <div class="flex space-x-4">
              <div>발행수:</div>
              <div class="tw-value">{{ nft_count }} 개</div>
            </div>
            <div class="flex space-x-4">
              <div>Contract Metadata:</div>
              <div class="tw-value">
                <a :href="getMetadata(`metadata`)"> LINK</a>
              </div>
            </div>
            <div>
              <div class="flex space-x-4">
                <div>description:</div>
                <div class="tw-value">{{ metadata.description }}</div>
              </div>
              <div class="flex space-x-4">
                <div>image:</div>
                <div class="tw-value">
                  <a :href="metadata.image">LINK</a>
                </div>
              </div>
              <div class="flex space-x-4">
                <div>external_link:</div>
                <div class="tw-value">
                  <a :href="metadata.external_link">LINK</a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img
              v-if="metadata !== null"
              :src="metadata.image"
              class="rounded-xl w-full"
            />>
          </div>
        </div>
      </div>

      <div class="space-x-4">
        <button class="tw-button-indigo" @click="onCreateNFT()">
          + NFT 만들기
        </button>

        <button class="tw-button-indigo" @click="onMintingFirst()">
          민팅먼저하기
        </button>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div
          class="p-4 rounded-xl bg-white"
          v-for="data in nft_list"
          :key="data.uid"
        >
          <RTZItem
            :source="data"
            :nft_count="nft_count"
            :contract="contract"
            :addr="addr"
            @updateCount="updateCount"
            @updateSource="updateSource"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Caver from "caver-js";
// import ContractMain from "@/abi/RocketZett-v12.json";
// import ContractMain from "@/abi/RocketZett-v13.json";
import ContractMain from "@/abi/ttigerz-v14.json";
// import ContractMain from "@/abi/RocketZett.json";
import RTZItem from "@/components/RTZItem.vue";
export default {
  components: {
    RTZItem,
  },
  data() {
    return {
      //
      symbol: "ttz",
      //
      contract: null,
      addr: null,
      addr_designer: null,
      //
      metadata: null,
      nft_count: -1,
      nft_list: [],
      //
      input_title: "",
      input_desc: "",
      upload_file: "",
      upload_image_url: "",
    };
  },
  unmounted() {
    this.$store.commitRoot("set_account_change_callback", () => {
      return true;
    });
  },
  async mounted() {
    console.log("RtzWorkPage mounted");
    await this.$store.waitAsync(1000);

    // 로그인 안돼있으면 로그인 시킨다.
    if (!this.$store.state.lv2_is_login) {
      this.$store.state.lv2_show_wallet_login = true;
      do {
        await this.$store.waitAsync(1000);
      } while (this.$store.state.lv2_login_doing);
    }

    if (!this.$store.state.lv2_is_login) {
      await this.$store.waitAsync(1000);
      this.$store.Alert("#ERR_NEED_KAIKAS");
      this.$router.push("/doum");
      return;
    }

    // const klaytn = window.klaytn;
    // if (klaytn === null || klaytn.selectedAddress === undefined) {
    //   this.$store.Alert("##ERR_NEED_KAIKAS");
    //   this.$router.push("/doum");
    //   return;
    // }
    const wallet = this.$store.state.lv2_login_data.wallet;

    // let networkId = 8217;
    // if (process.env.VUE_APP_MODE != "PUBLIC") {
    //   networkId = 1001;
    // }
    const networkId = process.env.VUE_APP_KLAYTN_NETWORK_ID;
    console.log("networkId check", networkId);
    if (`${wallet.networkVersion}` != `${networkId}`) {
      console.log("networkId fail #1", wallet.networkVersion, networkId);
      this.$store.Alert("#ERR_CONTRACT_NETWORK_ID");
      this.$router.push("/doum");
      return;
    }

    // const deplyedNetwork = ContractMain.networks[networkId];
    // if (deplyedNetwork === undefined) {
    //   console.log("networkId fail #2", klaytn.networkVersion, networkId);
    //   this.$store.Alert("#ERR_CONTRACT_NETWORK_ID");
    //   this.$router.push("/doum");
    //   return;
    // }

    console.log("try create contract");
    // const caver = new Caver(klaytn);
    // const contract = new caver.klay.Contract(
    //   ContractMain.abi,
    //   deplyedNetwork.address
    // );
    const contract = this.$store.get_contract(ContractMain);

    // console.log("try klaytn.enable");
    // let addr = null;
    // try {
    //   let accounts = await klaytn.enable();
    //   if (!Array.isArray(accounts) || accounts.length < 1) {
    //     throw Error(["need kaikas login", accounts]);
    //   }

    //   addr = accounts[0];
    // } catch (err) {
    //   console.log("err", err);
    //   this.$store.Alert(this.$t("#ERR_NEED_KAIKAS"));
    //   this.$router.push("/doum");
    //   return;
    // }
    const addr = this.$store.state.lv2_login_data.addr;
    console.log("addr", addr);

    this.$store.commitRoot("set_account_change_callback", () => {
      this.$store.Alert(this.$t("#ERR_WALLET_CHANGED"));
      this.$router.push("/doum");
      return true;
    });

    if (!this.$store.isRtzAddr(addr)) {
      console.log("addr check fail #2", addr);
      this.$store.Alert("#ERR_DOUM_DESIGNER_ONLY");
      this.$router.push("/doum");
      return;
    }

    const count = await contract.methods.totalSupply().call();
    this.nft_count = Number(count);
    console.log("totalSupply", this.nft_count);

    const addr_designer = await contract.methods.getDesignerAddr().call();
    // this.check = await contract.methods.check().call({
    //   from: addr,
    // });
    // this.version = await contract.methods.getVersion(0).call();
    // this.mint_status = await contract.methods.get_mint_status().call();

    const res = await this.$store.postAsyncNamed(`/${this.symbol}api`, {
      api: "get_nfts",
      symbol: this.symbol,
      type: "first",
      addr,
    });

    if (res.code != 100) {
      // console.log("get_nfts ng", res);
      this.$store.AlertPost(res);
      this.$router.push("/doum");
      return;
    }

    // console.log("get_nfts ok", res);
    this.$store.commitRoot("set_ui", this.$route.fullPath);

    this.metadata = res.metadata;
    this.nft_list = res.nft_list;
    this.contract = contract;
    this.addr = addr;
    this.addr_designer = addr_designer;
  },
  computed: {
    // upload_image_url(state) {
    //   return `${this.$store.GET_URL}/log_upload/${state.upload_fname}`;
    // },
  },
  methods: {
    getMetadata(data) {
      if (data == "metadata") {
        return `${process.env.VUE_APP_GET_URL}/${this.symbol}nft/metadata`;
      }
      return `${process.env.VUE_APP_GET_URL}/${this.symbol}nft/${data.uid}`;
    },
    changeFile() {
      const file = this.$refs.file.files[0];
      if (file.type.indexOf("image/") != 0) {
        console.log("changeFile not image", file);
        return;
      }

      console.log("changeFile", file);
      this.upload_file = file;
      this.upload_image_url = URL.createObjectURL(file);
    },
    onUploadCancel() {
      this.upload_file = "";
      this.upload_image_url = "";
    },
    async onAddNft() {
      console.log("onAddNft");

      const file = this.upload_file;
      const formData = new FormData();
      formData.append("file", file);

      let res;

      res = await this.$store.postAsyncUpload(
        `/${this.symbol}upload`,
        formData
      );
      if (res.code !== 100) {
        console.log("upload fail", res);
        return;
      }

      console.log("upload ok", res);
      const upload_fname = res.fname;

      res = await this.$store.postAsyncNamed(`/${this.symbol}api`, {
        api: "new_nft",
        symbol: this.symbol,
        title: this.input_title,
        description: this.input_desc,
        upload_fname,
      });

      if (res.code != 100) {
        console.log("new_nft ng", res);
        return;
      }

      // image upload wait
      await this.$store.waitAsync(3000);

      this.onUploadCancel();
      this.nft_list.unshift(res.data);
    },
    getCreateNFTUid() {
      let next_uid = 1;
      if (this.nft_list.length > 0) {
        next_uid = this.nft_list[0].uid + 1;
      }
      return next_uid;
    },
    async onMintingFirst() {
      const count = await this.contract.methods.totalSupply().call();
      const next = Number(count) + 1;
      console.log("onMintingFirst, count", count, next, this.addr);

      try {
        const tx = await this.contract.methods.mintById(next).send({
          from: this.addr,
          gas: "2000000",
        });
        console.log("mintById tx", tx);

        this.nft_count = next;
      } catch (err) {
        this.$store.Alert("#ERR_PD_MINT_FAIL");

        console.log("mintById fail", err);
        const errShort = err.message.substring(0, 40);
        this.$store.sendLog(["RTZItem mintById err", errShort, this.addr]);
        return;
      }
    },
    async onCreateNFT() {
      let last_uid = 0;
      if (this.nft_list.length > 0) {
        last_uid = this.nft_list[0].uid;
      }

      if (last_uid > this.nft_count) {
        this.$store.Alert(
          "추가는 마지막까지 발행이 완료되었을 경우에만 가능합니다."
        );
        return;
      }

      let next_uid = this.getCreateNFTUid();
      let res = await this.$store.postAsyncNamed(`/${this.symbol}api`, {
        api: "create_nft",
        symbol: this.symbol,
        uid: next_uid,
      });

      if (res.code !== 100) {
        this.$store.AlertPost(res);
        return;
      }

      this.nft_list.unshift(res.data);
    },
    async updateCount() {
      const count = await this.contract.methods.totalSupply().call();
      console.log("totalSupply", count);
      this.nft_count = Number(count);
    },
    updateSource(data_new) {
      console.log("updateSource", data_new);

      const uid = data_new.uid;
      for (let i = 0; i < this.nft_list.length; i++) {
        const data = this.nft_list[i];
        if (data.uid != uid) continue;

        const keys = Object.keys(data_new);
        keys.forEach((k) => {
          data[k] = data_new[k];
        });
        break;
      }
    },
  },
};
</script>

<style scoped>
.tw-button-indigo {
  @apply rounded-lg bg-indigo-800 border-2 border-white text-white px-4 py-2;
}
</style>
