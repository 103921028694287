<template>
  <div>
    <div class="flex justify-between mb-4">
      <div class="text-2xl font-boid">#{{ source.uid }}</div>
      <div>
        <a :href="getMetadata(source)">metadata</a>
      </div>
    </div>

    <div v-if="!edit">
      <div class="flex space-x-4">
        <div>제목:</div>
        <div class="tw-value">{{ source.title }}</div>
      </div>

      <div class="flex space-x-4">
        <div>설명:</div>
        <div class="tw-value">{{ source.description }}</div>
      </div>

      <div>
        <div>이미지</div>
        <img :src="source.image" />
      </div>
    </div>
    <div v-else>
      <div class="flex space-x-4">
        <div>제목:</div>
        <div class="tw-value">
          <input v-model="input_title" />
        </div>
      </div>

      <div class="flex space-x-4">
        <div>설명:</div>
        <div class="tw-value">
          <textarea class="tw-value" v-model="input_description" />
        </div>
      </div>

      <div class="">
        <div>이미지</div>
        <input type="file" ref="file" @change="changeFile" />
        <img :src="get_image_url" />
      </div>
    </div>

    <div class="space-x-4 mt-4">
      <button class="tw-button-indigo" v-if="!edit" @click="onEdit()">
        수정
      </button>
      <button class="tw-button-indigo" v-if="edit" @click="onCommit(true)">
        저장
      </button>
      <button class="tw-button-indigo" v-if="edit" @click="onCommit(false)">
        취소
      </button>

      <button
        class="tw-button-red"
        v-if="!edit && canMint(source)"
        @click="onMint(source)"
        :disabled="diableMint()"
      >
        발행
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: Object,
    nft_count: Number,
    contract: Object,
    addr: String,
  },
  data() {
    return {
      edit: false,
      upload_file: null,

      input_title: "",
      input_description: "",
      input_image_url: "",
    };
  },
  computed: {
    get_image_url() {
      if (this.edit) {
        return this.input_image_url;
      }
      return this.source.image;
    },
  },
  methods: {
    getMetadata(data) {
      return `${process.env.VUE_APP_GET_URL}/rtznft/${data.uid}`;
    },
    canMint(data) {
      return data.uid == this.nft_count + 1;
    },
    diableMint() {
      if (this.source.title == "") return true;
      if (this.source.image == "") return true;
      return false;
    },
    onEdit() {
      this.edit = true;
      this.input_title = this.source.title;
      this.input_description = this.source.description;
      this.input_image_url = this.source.image;
      this.upload_file = null;
    },
    changeFile() {
      const file = this.$refs.file.files[0];
      if (file.type.indexOf("image/") != 0) {
        console.log("changeFile ng, not image", file);
        return;
      }

      console.log("changeFile ok", file);
      this.upload_file = file;
      this.input_image_url = URL.createObjectURL(file);
    },
    async onCommit(b) {
      console.log("onCommit", b);

      if (!b) {
        this.edit = false;
        this.$emit("updateSource", { a: 1 }, 123);
        return;
      }

      let res;
      let upload_fname = null;

      // 이미지가 변경되었다면 업로드 먼저
      if (this.upload_file !== null) {
        console.log("onCommit image upload");

        const file = this.upload_file;
        const formData = new FormData();
        formData.append("file", file);

        res = await this.$store.postAsyncUpload("/rtzupload", formData);
        if (res.code !== 100) {
          // 업로드 실패
          this.$store.AlertPost(res);
          return;
        }
        upload_fname = res.fname;
      }

      console.log("onCommit try save_nft");
      res = await this.$store.postAsyncNamed("/rtzapi", {
        api: "save_nft",
        symbol: "rtz",
        uid: this.source.uid,
        title: this.input_title,
        description: this.input_description,
        upload_fname,
      });

      if (res.code != 100) {
        this.$store.AlertPost(res);
        return;
      }

      //   this.source.title = res.data.title;
      //   this.source.description = res.data.description;
      //   this.source.image = res.data.image;
      this.$emit("updateSource", res.data);

      this.edit = false;
    },
    async onMint() {
      const klaytn = window.klaytn;
      console.log("onMint", this.source.uid, klaytn.selectedAddress);

      const res = await this.$store.postAsyncNamed("/rtzapi", {
        api: "can_mint",
        addr: this.addr,
      });

      if (res.code != 100) {
        this.$store.AlertPost(res);
        return;
      }
      console.log("res", res);

      const ret = await this.contract.methods.check().call({
        from: this.addr,
      });
      console.log("check", ret);
      if (ret != "1") {
        this.$store.Alert("#ERR_DOUM_DESIGNER_ONLY");
        return;
      }

      try {
        const tx = await this.contract.methods.mintById(this.source.uid).send({
          from: this.addr,
          // gas: "1000000",
          gas: "2000000",
        });
        console.log("mintById tx", tx);
      } catch (err) {
        this.$store.Alert("#ERR_PD_MINT_FAIL");

        console.log("mintById fail", err);
        const errShort = err.message.substring(0, 40);
        this.$store.sendLog(["RTZItem mintById err", errShort, this.addr]);
        return;
      }

      await this.$store.waitAsync(3000);

      this.$emit("updateCount");
    },
  },
};
</script>

<style scoped>
.tw-button-indigo {
  @apply rounded-lg bg-indigo-600 text-white px-4 py-2;
}

.tw-button-red {
  @apply rounded-lg bg-red-600 text-white px-4 py-2;
}
</style>
